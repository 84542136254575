import React from "react"
import { Row, Col, Container, ListGroup, Button } from "react-bootstrap"
import avt from "../images/avatar.png"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { MailOutlined, PhoneOutlined } from "@ant-design/icons"
import sign from "../images/clm_sign.png"
import bus_station from "../images/bus_station.png"
import cyberbus from "../images/cyberbus.png"
import Clock from "../components/clock"
import { Link, graphql } from "gatsby"
import img_work from "../images/w1.jpg"
import img_pp from "../images/w2.jpg"
import fgs_logo from "../images/fgs.jpg"
import vyvy_logo from "../images/vyvy.png"
import red_logo from "../images/red.png"
import Slider from "react-slick";
import Img from "gatsby-image"

const IndexPage = ({data}) => {
  const siteTitle = data.site.siteMetadata.title
  const wPost = data.workItem.edges
  const pPost = data.pprojectItem.edges

  const slider_setting = {
    dots: false,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
  }

  const slider_setting_pc = {
    dots: false,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 2000,
    slidesToShow: 2,
    slidesToScroll: 1,
  }

  return (
  <Layout pageInfo={{ pageName: "index" }} style={{ margin: 0 }}>
    <SEO title="Art lover" keywords={[`gatsby`, `react`, `bootstrap`]} />
    <div className="top-slideshow">
      <ul class="slideshow-g">
        <li>
          {/* <h3>Pure CSS Fullscreen Fading Slideshow</h3> */}
          <span>Slide One</span>
        </li>
        <li>
          <span>Slide Two</span>
        </li>
        <li>
          <span>Slide Three</span>
        </li>
        <li>
          <span>Slide Four</span>
        </li>
      </ul>
      <ul class="slideshow-g">
        <li>
          <span>Slide One</span>
        </li>
        <li>
          <span>Slide Two</span>
        </li>
        <li>
          <span>Slide Three</span>
        </li>
        <li>
          <span>Slide Four</span>
        </li>
      </ul>
      <ul class="slideshow-g">
        <li>
          <span>Slide One</span>
        </li>
        <li>
          <span>Slide Two</span>
        </li>
        <li>
          <span>Slide Three</span>
        </li>
        <li>
          <span>Slide Four</span>
        </li>
      </ul>
      <ul class="slideshow-g">
        <li>
          <span>Slide One</span>
        </li>
        <li>
          <span>Slide Two</span>
        </li>
        <li>
          <span>Slide Three</span>
        </li>
        <li>
          <span>Slide Four</span>
        </li>
      </ul>
      <div class="box">
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
    {/* <Container className="index_top">
      <Container className="top_tivi"></Container>
      <Container className="busStop_wrapper">
        <img className="bus_station" src={bus_station} alt="bus stop" />
      </Container>
      <Container className="clock_wrapper">
        <Clock />
      </Container>
      <Container className="bus_wrapper">
        <img className="cyberbus" src={cyberbus} alt="cyber bus" />
      </Container>
      <iframe width="560" height="315" src="https://www.youtube.com/embed/UeyWZvu7Qj8?controls=0" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </Container> */}
    <Container className="index_work">
      <Row>
        <Col md={6}>
          <Container className="index_link_container">
            <Link to="/work/" className="index_work_link">
              <h2 className="title_font">Art work</h2>
              <div className="index_work_link_note">View more</div>
              <p className="index_work_des">
                My work for Post house, Production house, Agency, Clients
              </p>
              <Slider {...slider_setting} >
                {wPost.map(work => {
                  // const title = work.node.frontmatter.title || work.node.fields.slug
                  const node = work.node
                  const featuredImgFluid = node.frontmatter.img
                  // const year = new Date(node.frontmatter.date).getFullYear()
                  return (
                    <Link to={node.fields.slug}>
                      {!node.frontmatter.img ? (
                        <img
                          src={img_work}
                          alt="img work"
                          className="index_work_img"
                        />
                      ) : (
                        <Img className="index_work_img" fluid={featuredImgFluid.childImageSharp.fluid} />
                      )}
                    </Link>
                  )
                })}
              </Slider>
              {/* <img src={img_work} alt="img work" className="index_work_img" /> */}
            </Link>
          </Container>
        </Col>
        <Col md={6}>
          <Container className="index_link_container">
            <Link to="/pproject/" className="index_work_link">
              <h2 className="title_font">Spiritual</h2>
              <div className="index_work_link_note">View more</div>
              <p className="index_work_des">
                My spiritual Activity.
              </p>
              <Slider {...slider_setting} >
                {pPost.map(work => {
                  // const title = work.node.frontmatter.title || work.node.fields.slug
                  const node = work.node
                  const featuredImgFluid = node.frontmatter.img
                  // const year = new Date(node.frontmatter.date).getFullYear()
                  return (
                    <Link to={node.fields.slug}>
                      {!node.frontmatter.img ? (
                        <img
                          src={img_work}
                          alt="img work"
                          className="index_work_img"
                        />
                      ) : (
                        <Img className="index_work_img" fluid={featuredImgFluid.childImageSharp.fluid} />
                      )}
                    </Link>
                  )
                })}
              </Slider>
              {/* <img src={img_pp} alt="img pp" className="index_work_img" /> */}
            </Link>
          </Container>
        </Col>
      </Row>
    </Container>
    <Container className="index_work_pc">
      <Row className="mb-5">
        <Col md={8}>
        <Slider {...slider_setting_pc} >
                {wPost.map(work => {
                  // const title = work.node.frontmatter.title || work.node.fields.slug
                  const node = work.node
                  const featuredImgFluid = node.frontmatter.img
                  // const year = new Date(node.frontmatter.date).getFullYear()
                  return (
                    <Link to={node.fields.slug}>
                      {!node.frontmatter.img ? (
                        <img
                          src={img_work}
                          alt="img work"
                          className="index_work_img_pc"
                        />
                      ) : (
                        <Img className="index_work_img_pc" fluid={featuredImgFluid.childImageSharp.fluid} />
                      )}
                    </Link>
                  )
                })}
              </Slider>
        </Col>
        <Col md={4}>
          {/* <Container className="index_link_container"> */}
            <Link to="/work/" className="index_work_link_pc">
              <h2 className="title_font">Art Work</h2>
              <div className="index_work_link_note">View more</div>
              <p className="index_work_des">
                My work for Post house, Production house, Agency, Clients
              </p>
              
              {/* <img src={img_work} alt="img work" className="index_work_img" /> */}
            </Link>
          {/* </Container> */}
        </Col>
      </Row>
      <Row>
        <Col md={4}>
        {/* <Container className="index_link_container"> */}
            <Link to="/pproject/" className="index_work_link_pc">
              <h2 className="title_font">Spiritual</h2>
              <div className="index_work_link_note">View more</div>
              <p className="index_work_des">
                My Spiritual Activity.
              </p>
              
              {/* <img src={img_pp} alt="img pp" className="index_work_img" /> */}
            </Link>
          {/* </Container> */}
        </Col>
        <Col md={8}>
          <Slider {...slider_setting_pc} >
                {pPost.map(work => {
                  // const title = work.node.frontmatter.title || work.node.fields.slug
                  const node = work.node
                  const featuredImgFluid = node.frontmatter.img
                  // const year = new Date(node.frontmatter.date).getFullYear()
                  return (
                    <Link to={node.fields.slug}>
                      {!node.frontmatter.img ? (
                        <img
                          src={img_work}
                          alt="img work"
                          className="index_work_img_pc"
                        />
                      ) : (
                        <Img className="index_work_img_pc" fluid={featuredImgFluid.childImageSharp.fluid} />
                      )}
                    </Link>
                  )
                })}
              </Slider>
        </Col>
      </Row>
    </Container>
    <Container className="index_about">
      <Row>
        <Col
          md={7}
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Container className="info_about">
            <h1 className="title_font">Vũ Bình宇平</h1>
            <h6 style={{ color: "gray" }}>Art lover</h6>
            {/* <h6 style={{ color: "gray" }}>VFX supervisor / VFX producer</h6> */}
            <Container style={{ height: 20 }} />
            <Container className="whoami-link" ><Link className="glass" to="/about"><span className="glass-before"></span> &nbsp;&nbsp;&nbsp; <span>W</span><span>h</span><span>o</span> <span>a</span><span>m</span> <span>i</span> &nbsp; <span className="glass-after"></span> </Link></Container>
            <Container style={{ height: 20 }} />
            <p>
              - Sound healer - Reiki_0$_2024.
            </p>
            <p>
              - Filmmaker - VFX.
            </p>
            <Container style={{ height: 20 }} />
            <Row className="contactInfo_about">
              <Col md={10}>
                <Container id="contact" style={{ padding: 5 }}>
                  <MailOutlined className="icon_about" />
                  <a
                    style={{ color: "#fff" }}
                    href="mailto:vubinh.therapy@gmail.com"
                  >
                    vubinh.therapy@gmail.com
                  </a>
                </Container>
                <Container style={{ padding: 5 }}>
                  <PhoneOutlined className="icon_about" />
                  <a style={{ color: "#fff" }} href="tel:0939093977">
                    0938.0939.77
                  </a>
                </Container>
              </Col>
              <Col
                md={2}
                style={{
                  display: "flex",
                  alignItems: "flex-end",
                  justifyContent: "flex-end",
                }}
              >
                <img style={{ height: 60 }} src={sign} alt="clm_logo" />
              </Col>
            </Row>
          </Container>
        </Col>
        <Col
          md={5}
          className="avatar"
          // style={{
          //   display: "flex",
          //   justifyContent: "center",
          //   alignItems: "center",
          //   position: "relative",
          // }}
        >
          <Container className="glitch">
            <Container className="glitch-img"></Container>
            <Container className="glitch-img"></Container>
            <Container className="glitch-img"></Container>
            <Container className="glitch-img"></Container>
          </Container>
          {/* <img src={avt} alt="profile picture" className="img_about" /> */}
          {/* <div className="cyber_text">MAKE IT REAL</div> */}
        </Col>
      </Row>
    </Container>
    {/* <Container id="contact"></Container> */}
    {/* <Container className="myclient-wrapper">
      <Container className="myclient-header">
        <h2 className="title_font">My Clients</h2>
      </Container>
      <Container className="myclient">
        <Container className="myclient-item">
          <img src={fgs_logo} alt="fgs logo" />
          <p>FGS Production house</p>
        </Container>
        <Container className="myclient-item">
          <img src={vyvy_logo} alt="vyvy logo" />
          <p>Vy Vy haircare</p>
        </Container>
        <Container className="myclient-item">
          <img src={red_logo} alt="red logo" />
          <p>RedBox Motion</p>
        </Container>
      </Container>
    </Container>

    <Container className="myclient-wrapper">
      <Container className="myclient-header">
        <h2 className="title_font">My Partners</h2>
      </Container>
      <Container className="myclient">
        <Container className="myclient-item">
          <img src={red_logo} alt="red logo" />
          <p>RedBox Motion</p>
        </Container>
        <Container className="myclient-item"></Container>
        <Container className="myclient-item"></Container>
      </Container>
    </Container> */}
  </Layout>
)}

export default IndexPage

export const pageQuery = graphql`
         query {
           site {
             siteMetadata {
               title
             }
           }
           workItem: allMarkdownRemark(
             limit: 3
             sort: { fields: [frontmatter___date], order: DESC }
             filter: { frontmatter: { template: { eq: "work" } } }
           ) {
             edges {
               node {
                 frontmatter {
                   template
                   title
                   tags
                   description
                   slug
                   date
                   category
                   img {
                     childImageSharp {
                       fluid(maxWidth: 800) {
                         ...GatsbyImageSharpFluid
                       }
                     }
                   }
                 }
                 fields {
                   slug
                 }
               }
             }
           }
           pprojectItem: allMarkdownRemark(
             limit: 3
             sort: { fields: [frontmatter___date], order: DESC }
             filter: { frontmatter: { template: { eq: "post" } } }
           ) {
             edges {
               node {
                 excerpt
                 fields {
                   slug
                 }
                 frontmatter {
                   date(formatString: "MMMM DD, YYYY")
                   template
                   title
                   tags
                   description
                   slug
                   category
                   img {
                     childImageSharp {
                       fluid(maxWidth: 800) {
                         ...GatsbyImageSharpFluid
                       }
                     }
                   }
                 }
               }
             }
           }
         }
       `